// import './shared/config/wdyr'

import 'bootstrap/dist/css/bootstrap.css'
import React, { Suspense } from 'react'

import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import ErrorBoundary from './components/ErrorBoundary'
import Loader from './components/Loader'
import storeConfig from './redux/store'

const store = storeConfig()
// if (process.env.NODE_ENV === 'production') console.log = () => {}
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={store.__PERSISTOR} loading={null}>
      <ErrorBoundary>
        <Suspense fallback={<Loader isLoading={true} />}>
          <App />
        </Suspense>
      </ErrorBoundary>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
