import { localApi } from '../../../shared/config/axiosConfig'
import {
  ADD_OBJECT_TO_INPROGRESS,
  REMOVE_OBJECT_FROM_INPROGRESS,
  SET_GIM_AUTH_TOKEN,
} from '../../types/user'

export const addObjectToInprogress = (data) => async (dispatch) => {
  dispatch({
    type: ADD_OBJECT_TO_INPROGRESS,
    payload: data,
  })
}

export const removeObjectFromInprogress = (data) => async (dispatch) => {
  dispatch({
    type: REMOVE_OBJECT_FROM_INPROGRESS,
    payload: data,
  })
}

export const initializeConnection = () => async (dispatch) => {
  const response = await localApi({ url: '/Initialize', method: 'GET' })
  return response.data
}

export const setGimAuthToken = (data) => async (dispatch) => {
  localApi.defaults.headers.common.Authorization = data ? `Bearer ${data}` : ''
  dispatch({
    type: SET_GIM_AUTH_TOKEN,
    payload: data,
  })
}

export const getCurrentAppVersion = () => async (dispatch) => {
  const response = await localApi({
    url: '/GetLatestVersion',
    method: 'GET',
  })
  return response.data
}

export const uploadFile = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: '/UploadFile',
    data,
  })
  return response.data
}

export const downloadFile = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: '/DownloadFile',
    data,
  })
  return response.data
}

export const ConvertObject = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: '/ConvertObject',
    data,
  })
  return response.data
}

export const getFileContent = (file, isGIMtransfer) => async (dispatch) => {
  let params = isGIMtransfer ? '&isGIMtransfer=true' : ''
  const response = await localApi({
    method: 'GET',
    url: `/FileView?FileName=${file}${params}`,
    params,
  })
  return response.data
}
/*
* Used to fetch a json file to view in the object viewer.
*/
export const getFile = (file, isGIMtransfer) => async (dispatch) => {
  let params = isGIMtransfer ? '&isGIMtransfer=true' : ''
  const response = await localApi({
    method: 'GET',
    url: `/FileView?FileName=${file}${params}`,
  })
  return response
}
/*
* Used to request the conversion of a file to a simple glb to place on gimmaps.
* params: (data)
*   fileName: string
*   forGimMap: bool
*/
export const getFileGlb = (data) => async (dispatch) =>{
  const response = await localApi({
    method: 'POST', 
    url: '/GetFileGlb',
    data,
  });
  return response;
}

export const findFileByGUID = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: '/FindFileByGUID',
    data,
  })
  return response
}

export const setProjectDirectory = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: '/SendProjectDirectoryPath',
    data,
  })
  return response.data
}

export const requestFileRead = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: 'ReadFileOld',
    data,
  })
  return response.data
}

export const AddGIMtransferObject = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: 'AddGIMtransferObject',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
  return response.data
}

export const requestFileUpload = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: 'UpdateObject',
    data,
  })
  return response.data
}

export const deleteObject = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: 'DeleteObject',
    data,
  })
  return response.data
}

export const requestFileRename = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: 'RenameFile',
    data,
  })
  return response.data
}

export const deleteProject = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: 'DeleteProject',
    data,
  })
  return response.data
}

export const getFileTypes = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: 'FindWriteTypes?GUID=' + data,
    // data,
  })
  if (response.status === 200 && !response.data.hasError) {
    // dispatch({
    //   type: FILE_TYPES,
    //   payload: response.data,
    // })
  }
  return response.data
}

export const getAllowedExtensions = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'GET',
    url: 'GetAllowedExtensionList',
  })
  return response.data
}

export const directDownloadFile = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: '/DirectDownloadFile',
    data,
  })
  return response.data
}

export const getPlotsView = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: '/GetPlotsView',
    data,
  })
  return response.data
}

export const getPlotsObjectView = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: '/GetPlotObjectsView',
    data,
  })
  return response.data
}

export const addLocation = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: '/AddObjectLocation',
    data,
  })
  return response
}

export const downloadGimMapCad = (data) => async (dispatch) => {
  const response = await localApi({
    method: 'POST',
    url: '/DownloadGLBData',
    data,
  })
  return response
}

export const checkMandatoryVersion = () => async (dispatch) => {
  const response = await localApi({
    method: 'GET',
    url: '/CheckMandatoryVersion',
  })
  return response
}
